:root {
    --input-padding-x: .75rem;
    --input-padding-y: .75rem;
}

.form-control {
    height:100%
}
  
.form-label-group {
    position: relative;
    margin-bottom: 1rem;

    select {
        -webkit-appearance: none;
    }

    select.form-control:not([size]):not([multiple]) {
        height: calc(3rem + 2px);
    }

    > input,
    > select,
    > textarea,
    > label {
        padding: var(--input-padding-y) var(--input-padding-x);
    }

    > label {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        margin-bottom: 0; /* Override default `<label>` margin */
        line-height: 1.5;
        border: 1px solid transparent;
        border-radius: .25rem;
        transition: all .1s ease-in-out;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        color: transparent;
    }

    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
        color: transparent;
    }

    input::-ms-input-placeholder,
    textarea::-ms-input-placeholder {
        color: transparent;
    }

    input::-moz-placeholder,
    textarea::-moz-placeholder {
        color: transparent;
    }

    input::placeholder,
    textarea::placeholder {
        color: transparent;
    }

    input:not(:placeholder-shown),
    textarea:not(:placeholder-shown) {
        padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
        padding-bottom: calc(var(--input-padding-y) / 3);
    }

    input:not(:placeholder-shown) ~ label,
    textarea:not(:placeholder-shown) ~ label {
        padding-top: calc(var(--input-padding-y) / 3);
        padding-bottom: calc(var(--input-padding-y) / 3);
        font-size: 12px;
    }

    select.form-control:not([size]):not([multiple]) ~ label {
        display: none
    }

    &.error {
        > label.error {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            margin-bottom: 0; /* Override default `<label>` margin */
            line-height: 1.5;
            border: 1px solid transparent;
            border-radius: .25rem;
            transition: all .1s ease-in-out;
            text-align: right;
        }
    }

    &.success > label.valid {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        margin-bottom: 0; /* Override default `<label>` margin */
        line-height: 1.5;
        border: 1px solid transparent;
        border-radius: .25rem;
        transition: all .1s ease-in-out;
        text-align: right;
    }
}
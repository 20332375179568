@import './version-band.scss';
 @import './version-band.color.scss';
 
@import './site.scss';
@import './site.fonts.scss';
@import './site.animation.scss';
@import './cover.scss';
@import './cover.color.scss';
@import './cover.navbar.color.scss';
@import './cover.navbar.scss';
@import './scroll.scss';
@import './summary.scss';
@import './summary.color.scss';
@import './skills.scss';
@import './skills.color.scss';
@import './skills-icons.scss';
@import './timeline.scss';
@import './timeline.color.scss';
@import './resume.scss';
@import './resume.color.scss';
@import './contact.scss';
@import './contact.color.scss';
@import './form.scss';
@import './form.color.scss';
@import './footer.scss';
@import './footer.color.scss';

@import './modal-full-page.scss';
@import './modal-full-page.color.scss';


@import './launch.scss';
@import 'https://ameyrupji.github.io/js-background-star-twinkle/src/v1-0-0/starTwinkle.css';

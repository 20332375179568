$font-weight-default: 100;
$font-weight-bold: 900;

$font-color-light: rgba(0, 0, 0, 1);
$title-color-light: rgba(0, 0, 0, 1);

$font-color-dark: rgba(255, 255, 255, 1);
$title-color-dark: rgba(255, 255, 255, 1);

$background-color-1-light: rgba(255, 255, 255, 1);
$background-color-1-light-alternate: rgba(239, 239, 239, 1);

$background-color-1-dark: rgba(22, 23, 25, 1);
$background-color-1-dark-alternate: rgba(22, 23, 25, 1);

$background-color-2-light: rgba(53, 92, 125, 1);
$background-color-2-light-alternate: rgba(31, 55, 75, 1);


$background-color-3-light: rgba(189, 149, 94, 1);
$background-color-3-light-alternate: rgba(132, 80, 7, 1);

$background-color-4-light: rgba(3, 93, 52, 1);
$background-color-4-light-alternate: rgba(31,75, 55, 1);

$background-color-5-light: rgba(216, 31, 40, 1);
$background-color-5-light-alternate: rgba(153, 25, 30, 1);



$gradient-1-light: linear-gradient(141deg, rgba(189, 141, 75, 1), rgba(53, 92, 125, 1));
$-webkit-gradient-1-light: -webkit-linear-gradient(141deg, rgba(189, 141, 75, 1), rgba(53, 92, 125, 1));
$-moz-gradient-1-light: -moz-linear-gradient(141deg, rgba(189, 141, 75, 1), rgba(53, 92, 125, 1));
$-o-gradient-1-light: -o-linear-gradient(141deg, rgba(189, 141, 75, 1), rgba(53, 92, 125, 1));


$gradient-2-light: linear-gradient(141deg,rgba(31, 55, 75, 1), rgba(53, 92, 125, 1));
$-webkit-gradient-2-light: -webkit-linear-gradient(141deg, rgba(31, 55, 75, 1), rgba(53, 92, 125, 1));
$-moz-gradient-2-light: -moz-linear-gradient(141deg, rgba(31, 55, 75, 1), rgba(53, 92, 125, 1));
$-o-gradient-2-light: -o-linear-gradient(141deg, rgba(31, 55, 75, 1), rgba(53, 92, 125, 1));

$gradient-2-dark: linear-gradient(141deg,rgba(31, 55, 75, 1), rgba(45, 79, 107, 1));
$-webkit-gradient-2-dark: -webkit-linear-gradient(141deg, rgba(31, 55, 75, 1), rgba(45, 79, 107, 1));
$-moz-gradient-2-dark: -moz-linear-gradient(141deg, rgba(31, 55, 75, 1), rgba(45, 79, 107, 1));
$-o-gradient-2-dark: -o-linear-gradient(141deg, rgba(31, 55, 75, 1), rgba(45, 79, 107, 1));

$gradient-3-light: linear-gradient(141deg, rgba(132, 80, 7, 1), rgba(189, 149, 94, 1));
$-webkit-gradient-3-light: -webkit-linear-gradient(141deg, rgba(132, 80, 7, 1), rgba(189, 149, 94, 1));
$-moz-gradient-3-light: -moz-linear-gradient(141deg, rgba(132, 80, 7, 1), rgba(189, 149, 94, 1));
$-o-gradient-3-light: -o-linear-gradient(141deg, rgba(132, 80, 7, 1), rgba(189, 149, 94, 1));

$gradient-3-dark: linear-gradient(141deg, rgba(132, 80, 7, 1), rgba(165, 124, 67, 1));
$-webkit-gradient-3-dark: -webkit-linear-gradient(141deg, rgba(132, 80, 7, 1), rgba(165, 124, 67, 1));
$-moz-gradient-3-dark: -moz-linear-gradient(141deg, rgba(132, 80, 7, 1), rgba(165, 124, 67, 1));
$-o-gradient-3-dark: -o-linear-gradient(141deg, rgba(132, 80, 7, 1), rgba(165, 124, 67, 1));


$gradient-4-light: linear-gradient(141deg, rgba(31, 75, 55, 1), rgba(3, 93, 52, 1));
$-webkit-gradient-4-light: -webkit-linear-gradient(141deg, rgba(31, 75, 55, 1), rgba(3, 93, 52, 1));
$-moz-gradient-4-light: -moz-linear-gradient(141deg, rgba(31, 75, 55, 1), rgba(3, 93, 52, 1));
$-o-gradient-4-light: -o-linear-gradient(141deg, rgba(31, 75, 55, 1), rgba(3, 93, 52, 1));

$gradient-4-dark: linear-gradient(141deg, rgba(2, 68, 38, 1), rgba(31, 75, 55, 1));
$-webkit-gradient-4-dark: -webkit-linear-gradient(141deg, rgba(2, 68, 38, 1), rgba(31, 75, 55, 1));
$-moz-gradient-4-dark: -moz-linear-gradient(141deg, rgba(2, 68, 38, 1), rgba(31, 75, 55, 1));
$-o-gradient-4-dark: -o-linear-gradient(141deg, rgba(2, 68, 38, 1), rgba(31, 75, 55, 1));


$gradient-5-light: linear-gradient(141deg, rgba(153, 25, 30, 1), rgba(216, 31, 40, 1));
$-webkit-gradient-5-light: -webkit-linear-gradient(141deg, rgba(153, 25, 30, 1), rgba(216, 31, 40, 1));
$-moz-gradient-5-light: -moz-linear-gradient(141deg, rgba(153, 25, 30, 1), rgba(216, 31, 40, 1));
$-o-gradient-5-light: -o-linear-gradient(141deg, rgba(153, 25, 30, 1), rgba(216, 31, 40, 1));


$gradient-1-light-transparent: linear-gradient(141deg, rgba(189, 141, 75, 0.9), rgba(53, 92, 125, 0.9));
$gradient-1-dark-transparent: linear-gradient(141deg, rgba(132, 80, 7, 0.9), rgba(31, 55, 75, 0.9));


$navbar-background: rgba(255, 255, 255, 0.7);
$navbar-shadow: rgba(0, 0, 0, 0.2);
$navbar-items: rgba(255, 255, 255, .8);
$navbar-items-hover: rgba(255, 255, 255, 1);


$navbar-scrolled-background-light: rgba(255, 255, 255, 0.7);
$navbar-scrolled-items-light: rgba(0, 0, 0, 0.7);
$navbar-scrolled-items-hover-light: rgba(0, 0, 0, 1);

$navbar-scrolled-background-dark: rgba(0, 0, 0, 0.7);
$navbar-scrolled-items-dark: rgba(255, 255, 255, 0.7);
$navbar-scrolled-items-hover-dark: rgba(255, 255, 255, 1);

$navbar-opened-background-light: rgba(0, 0, 0, 0.7);
$navbar-opened-items-light: rgba(0, 0, 0, 0.5);
$navbar-opened-items-hover-light: rgba(0, 0, 0, 1);


$navbar-opened-background-dark: rgba(0, 0, 0, 0.7);
$navbar-opened-items-dark: rgba(0, 0, 0, 0.5);
$navbar-opened-items-hover-dark: rgba(0, 0, 0, 1);


$modal-background-color-light: rgba(255, 255, 255, 1);
$modal-font-color-light: rgba(0, 0, 0, 1);

$modal-background-color-dark: rgba(22, 23, 25, 1);
$modal-font-color-dark: rgba(255, 255, 255, 1);



$form-element-background-light: #ffffff;
$form-label-color-light: #495057;
$form-label-shown-color-light: #777777;
$form-error-color-light:  #FF0000;
$form-success-color-light:  #008000;


$form-element-background-dark: rgba(22, 23, 25, 1);
$form-label-color-dark: #ffffff;
$form-label-shown-color-dark: #ffffff;
$form-error-color-dark:  #FF0000;
$form-success-color-dark:  #008000;


$timeline-outlines-light: rgba(204, 204, 204, 1);
$timeline-block-shadow-light: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
$timeline-block-shadow-hover-light: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

$timeline-outlines-dark: rgba(47, 47, 50, 1);
$timeline-block-shadow-dark: 0 4px 8px 0 rgba(200, 200, 200, 0.2);
$timeline-block-shadow-hover-dark: 0 8px 16px 0 rgba(200, 200, 200, 0.2);

@import './variables.scss';

.navbar { 
    &.nav-scrolled-20 {
        background: $navbar-scrolled-background-light;

        .navbar-brand {
            color: $navbar-scrolled-items-hover-light;
        }
        
        .nav-item {
            a {
                color: $navbar-scrolled-items-light;
                
                &:hover {
                    color: $navbar-scrolled-items-hover-light;
                }
            }
        }

        #burger .bar {
            background: $navbar-scrolled-items-hover-light;
        }
    }

    &.navbar-open {
        .navbar-brand {
            color: $font-color-light;
        }
    }

    #burger .bar {
        background: $navbar-items-hover;
    }
}

@media (prefers-color-scheme: dark) {
    .navbar {
        &.nav-scrolled-20 {
            background: $navbar-scrolled-background-dark;

            .navbar-brand {
                color: #fff;
            }
            
            .nav-item {
                a {
                    color: $navbar-scrolled-items-dark;
                    
                    &:hover {
                        color: $navbar-scrolled-items-hover-dark;
                    }
                }
            }

            #burger .bar {
                background: $navbar-scrolled-items-hover-dark;
            }
        }

        &.navbar-open {
            .navbar-brand {
                color: $font-color-dark;
            }
        }
    }
}
@import './variables.scss';

#skills-main {
    padding-top: 150px;
    padding-bottom: 100px;

    .title {
        color : #fff;
    }
    
    .unused{
        opacity: .5;
        &.btn {
            &:hover {
                opacity: 1;
                cursor: pointer;
            }
        }
    }

    .skills-block {
        padding: 23px;
        margin: auto;
        
        i {
            font-size: 60px;
        }

        i,
        p, a {
            animation: fade-in 2s;
            -moz-animation: fade-in 2s; /* Firefox */
            -webkit-animation: fade-in 2s; /* Safari and Chrome */
            -o-animation: fade-in 2s; /* Opera */
            color: #fff;
        }

    }

    #skills-key-div {
        float: right;
        padding-right: 3rem;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            color: #fff;

            li {
                float: left;
                padding-left: 1rem;
            }
        }
    }
}




@media (max-width:768px) {
    #skills-main {
        .skills-block {
            padding: 20px 0px;
        
            i {
                font-size: 50px;
            }
        }
    }
}   


@media only screen and (min-width:769px) and (max-width: 1169px) {

}

@media only screen and (min-width: 1170px) {

}
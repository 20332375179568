@import './variables.scss';

#skills-main {
    background-color: $background-color-3-light;
    background: $gradient-3-light;
    background: $-webkit-gradient-3-light;
    background: $-moz-gradient-3-light;
    background: $-o-gradient-3-light;
}

@media (prefers-color-scheme: dark) {

    #skills-main {
        background-color: $background-color-3-light-alternate;
        background: $gradient-3-dark;
        background: $-webkit-gradient-3-dark;
        background: $-moz-gradient-3-dark;
        background: $-o-gradient-3-dark;
    }
}
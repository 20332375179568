@import './variables.scss';

$font-color: rgba(255, 255, 255, 1);

#image-back-main {
    background-image: url("../images/background.jpg");
    background-size: cover;
    height: 100%;
    width: 100%;
    height: 100vh;
    display: flex;
    background-position: 50% 0%;
    background-size: cover;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 0;

    #gradient-main {
        width: 100%;
        padding: 0;
        margin: 0;
        border-radius: 0;
    }

    .fixed-position {
        overflow: hidden;
    }

    .title {
        animation: fade-in 1s;
        -moz-animation: fade-in 1s; /* Firefox */
        -webkit-animation: fade-in 1s; /* Safari and Chrome */
        -o-animation: fade-in 1s; /* Opera */
    }

    h1.title {
        font-size: 75px;
        color: $font-color;
    }
}


#jumbotron-content {
    padding-top: 200px;
    color: #fff !important;
    text-align: center;
    font-weight: 200;
    border-radius: 0;

    .title {
        color: #fff;
    }

    .title-hollow {
        -webkit-text-stroke-color: #fff;
    }
}
  

@media (max-width:768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  
    #image-back-main {   
        h1.title {
            font-size: 50px;
        }
  
        h3.title {
            font-size: 20px;
        }
    }
}

@media only screen and (min-width:769px) and (max-width: 1169px) {
}
@import './variables.scss';

#summary-main {
    background-color: $background-color-1-light;

    .title {
        color: $font-color-light;
    }

    .card-deck {
        .card {
            background-color: transparent !important;
            border-color: $background-color-1-light-alternate;

            .card-header {
                background-color: transparent !important;
                border-bottom: 0px;

                i {
                    border-color: $background-color-1-light-alternate;
                    color: $font-color-light;
                    background-color: $background-color-1-light;
                }
            }

            .card-body {
                p {
                    color: $font-color-light;;
                }
            }
        }
    }
}

@media (prefers-color-scheme: dark) {
    #summary-main {
        background-color: $background-color-1-dark;

        .title {
            color: $font-color-dark;
        }

        .card-deck {
            .card {
                background-color: transparent !important;
                border-color: $background-color-1-light-alternate;

                .card-header {
                    background-color: transparent !important;
                    border-bottom: 0px;

                    i {
                        border-color: $background-color-1-light-alternate;
                        color: $font-color-dark;
                        background-color: $background-color-1-dark;
                    }
                }

                .card-body {
                    p {
                        color: $font-color-dark;
                    }
                }
            }
        }
    }
}
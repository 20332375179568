.scroll-container {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.scroll-container .unu {
  margin-top: 6px;
}

.scroll-container .unu, .doi, .trei
{
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
}

.scroll-container .unu
{
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;
}

.scroll-container .doi
{
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}

.scroll-container .trei
{
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;
}

.scroll-container span
{
  display: block;
  width: 10px;
  height: 10px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);

  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 6px;
}

.scroll-container .mouse
{
  height: 35px;
  width: 24px;
  border-radius: 17px;
  transform: none;
  border: 2px solid white;
  top: 170px;
}

.scroll-container .wheel
{
  height: 8px;
  width: 3px;
  display: block;
  margin: 5px auto;
  background: white;
  position: relative;
  border-radius: 1px;
}

.scroll-container .wheel
{
  -webkit-animation: mouse-wheel 1.2s ease infinite;
  -moz-animation: mouse-wheel 1.2s ease infinite;
}

@media (max-width:1169px) {
  .scroll-container .mouse {
    display: none;
  }
}

@-webkit-keyframes mouse-wheel
{
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}

@-moz-keyframes mouse-wheel
{
  0% { top: 1px; }
  50% { top: 2px; }
  100% { top: 3px;}
}

@-webkit-keyframes mouse-scroll {

  0%   { opacity: 0;}
  50%  { opacity: .5;}
  100% { opacity: 1;}
}
@-moz-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@-o-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}

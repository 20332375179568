@import './variables.scss';


.navbar {
    .navbar-brand,
    .nav-item a,
    .fixed-top .nav-item a {
        text-transform: uppercase;
    }

    .navbar-brand {
        font-weight: $font-weight-default;
    }

    #navbar-brand-alternate {
        font-weight: $font-weight-bold;
    }

    &.bg-transparent {
        transition: .5s;
        padding-top: 20px;
        background-color: transparent !important;
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
        
        .navbar-brand {
            color: #fff;
        }
        
        .nav-item {
            a {
                font-weight: $font-weight-default;
                color: $navbar-items;

                &:hover {
                    color: $navbar-items-hover;
                }
            }
        }
    }

    &.nav-scrolled-20 {
        transition: 1s;
        padding-top: 8px;
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px);

        .nav-item {
            a {
                font-weight: $font-weight-default;
            }
        }

    }

    &.nav-scrolled-vh {
        box-shadow: $navbar-shadow 0px 1px 4px 0px;
    }

    &.fixed-top {
      animation: fade-in 1s;
      -moz-animation: fade-in 1s; /* Firefox */
      -webkit-animation: fade-in 1s; /* Safari and Chrome */
      -o-animation: fade-in 1s; /* Opera */
    }
}

@media (max-width:768px) {
    .navbar {
        .navbar-toggler {
            border: 0px;
        }

        #burger {
            width: 18px;
            height: 8px;
            position: relative;
            display: block;
            margin: -8px auto 0;
            top: 50%;

            .bar {
                width: 100%;
                height: 1px;
                display: block;
                position: relative;
                transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
                transition-delay: 0s;

                &.top {
                    -webkit-transform: translateY(0px) rotate(0deg);
                            transform: translateY(0px) rotate(0deg);
                }
                
                &.bottom {
                    -webkit-transform: translateY(6px) rotate(0deg);
                            transform: translateY(6px) rotate(0deg);
                }

            }

        }
        
        &.bg-transparent.navbar-open {
            .container { 
                padding: 1rem 1rem;
            }
        }

        &.navbar-open {
            transition: 1s;
            -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
            min-height: 100vh;

            .container { 
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                padding: 8px 16px;
            }

            li.nav-item {
                transition: 1s;  
            }
            
            #burger .bar {
                transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
                transition-delay: 0.2s;

                &.top {
                    -webkit-transform: translateY(4px) rotate(45deg);
                            transform: translateY(4px) rotate(45deg);
                }

                &.bottom {
                    -webkit-transform: translateY(3px) rotate(-45deg);
                            transform: translateY(3px) rotate(-45deg);
                }
            }

            .nav-item {
                padding-left: 10px;
            }

            i {
                opacity: .3;
            }
        }
    }
}

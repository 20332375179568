.modal-full {

    .modal-content {
        background-color: $modal-background-color-light;

        .modal-title,
        h2,
        h4,
        .title-light,
        p,
        i,
        .close,
        li  {
            color: $title-color-light;
        }
    }

}

@media (prefers-color-scheme: dark) {
    .modal-full {
        .modal-content {
            background-color: $modal-background-color-dark;

            .modal-title,
            h2, 
            h4,
            .title-light,
            p,
            i,
            .close,
            li {
                color: $title-color-dark;
            }
        }
    }
}
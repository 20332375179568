@import './variables.scss';


*.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

body, html {
    width: 100%;
    height: 100%;
}

body {
    background-color: $background-color-1-light;
    .modal-open {
        overflow: hidden;
    }
}

.container {
    .container-title {
        margin-bottom: 50px;
    }

    .container-btns {
        margin-bottom: 20px;
    }
}

@keyframes fade-in {
    from { opacity:0; }
    to { opacity:1; }
}
@-moz-keyframes fade-in { /* Firefox */
    from { opacity:0; }
    to { opacity:1; }
}
@-webkit-keyframes fade-in { /* Safari and Chrome */
    from { opacity:0; }
    to { opacity:1; }
}
@-o-keyframes fade-in { /* Opera */
    from { opacity:0; }
    to { opacity:1; }
}
@import './variables.scss';


#timeline-main {
    background-color: $background-color-1-light-alternate;

    .background-images {
        background-image: url("../images/timeline-logos.png");
    }

    .title {
        color: $font-color-light;
    }

    #timeline {
        &::before {
            background: $timeline-outlines-light;
        }
    }

    .timeline-img-container {
        background-color: $background-color-1-light-alternate;
        border: 3px solid $timeline-outlines-light;

        &.top {
            img {
                border: 4px solid $timeline-outlines-light;

            }
        }
    }

    .timeline-img-container-alt {
        background: $background-color-2-light-alternate;
        background: $gradient-2-light;
        background: $-webkit-gradient-2-light;
        background: $-moz-gradient-2-light;
        background: $-o-gradient-2-light;
        border: 3px solid $timeline-outlines-light;
    }

    .timeline-content {
        border: 1px solid $timeline-outlines-light;
        background-color: $background-color-1-light-alternate;

        -webkit-box-shadow: $timeline-block-shadow-light;
        box-shadow: $timeline-block-shadow-light;

        &:hover {
            -webkit-box-shadow: $timeline-block-shadow-hover-light;
            box-shadow: $timeline-block-shadow-hover-light;
        }
    }

    .timeline-block-content {
        .heading {
            background-color: $background-color-4-light-alternate;
            background: $gradient-2-light;
            background: $-webkit-gradient-2-light;
            background: $-moz-gradient-2-light;
            background: $-o-gradient-2-light;
        }

        .heading-secondary {
            background-color: #ddd; 
        }

        .read-more p {
            color: #777;
        }
    }
}

@media (max-width:768px) {
    #timeline-main {
        .timeline-block {
            .timeline-content {
                &::before {
                    border-right: 10px solid $timeline-outlines-light;
                }

                &::after {
                    border-right: 9px solid #355c7d;
                }
            }
        }
    }
}

@media only screen and (min-width:769px) and (max-width: 1169px) {
    #timeline-main {
        .timeline-block {
            .timeline-content {
                &::before {
                    border-right: 15px solid $timeline-outlines-light;
                }

                &::after {
                    border-right: 14px solid rgba(45, 79, 107, 1);
                }
            }
        }
    }
}

@media only screen and (min-width: 1170px) {

    #timeline-main {
        .timeline-block {
            &:nth-child(even) .timeline-content  {
                &::before {
                    border-right: 15px solid $timeline-outlines-light;
                }

                &::after {
                    border-right: 14px solid rgba(53, 92, 125, 1);
                }
            }

            &:nth-child(odd) .timeline-content  {
                &::before {
                    border-left: 15px solid $timeline-outlines-light;
                }

                &::after {
                    border-left: 14px solid rgba(31, 55, 75, 1);
                }
            }
        }
    }
}

@media (prefers-color-scheme: dark) {

    #timeline-main {
        background-color: $background-color-1-dark;

        .background-images {
            background-image: url("../images/timeline-logos-grayscale.png");
        }

        .title {
            color: $font-color-dark;
        }

        #timeline {
            &::before {
                background: $timeline-outlines-dark;
            }
        }

        .timeline-img-container {
            background-color: $background-color-1-dark-alternate;
            border: 3px solid $timeline-outlines-dark;

            &.top {
                img {
                    border: 4px solid $timeline-outlines-dark;
    
                }
            }
        }
    
        .timeline-img-container-alt {
            background: $background-color-1-dark;
            background: $gradient-2-dark;
            background: $-webkit-gradient-2-dark;
            background: $-moz-gradient-2-dark;
            background: $-o-gradient-2-dark;
            border: 3px solid $timeline-outlines-dark;
        }

        .timeline-content {
            border: 1px solid $timeline-outlines-dark;
            background-color: #5b5c5e;

            p {
                color: #fff;
            }
            -webkit-box-shadow: $timeline-block-shadow-dark;
            box-shadow: $timeline-block-shadow-dark;

            &:hover {
                -webkit-box-shadow: $timeline-block-shadow-hover-dark;
                box-shadow: $timeline-block-shadow-hover-dark;
            }
        }

        .timeline-block-content {
            .heading {
                background-color: $background-color-2-light-alternate;
                background: $gradient-2-dark;
                background: $-webkit-gradient-2-dark;
                background: $-moz-gradient-2-dark;
                background: $-o-gradient-2-dark;
            }

            .heading-secondary {
                background-color: #444546;
                
                p{
                    color: #fff;
                }
            }

            .read-more p {
                color: #fff;
            }
        }
    }

    @media (max-width:768px) {
        #timeline-main {
            .timeline-block {
                .timeline-content {
                    &::before {
                        border-right: 10px solid $timeline-outlines-dark;
                    }
    
                    &::after {
                        border-right: 9px solid rgba(45, 79, 107, 1);
                    }
                }
            }
        }
    }


    @media only screen and (min-width:769px) and (max-width: 1169px) {
        #timeline-main {
            .timeline-block {
                .timeline-content {
                    &::before {
                        border-right: 15px solid $timeline-outlines-dark;
                    }

                    &::after {
                        border-right: 14px solid rgba(45, 79, 107, 1);
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1170px) {

        #timeline-main {
            .timeline-block {
                &:nth-child(even) .timeline-content  {
                    &::before {
                        border-right: 15px solid $timeline-outlines-dark;
                    }

                    &::after {
                        border-right: 14px solid rgba(45, 79, 107, 1);
                    }
                }

                &:nth-child(odd) .timeline-content  {
                    &::before {
                        border-left: 15px solid $timeline-outlines-dark;
                    }

                    &::after {
                        border-left: 14px solid rgba(31, 55, 75, 1);
                    }
                }
            }
        }
    }
}

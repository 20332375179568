@import './variables.scss';

#timeline-main {
    background-color: $background-color-1-light-alternate;
    padding-top: 150px;
    padding-bottom: 100px;
    overflow: hidden;
    
    .btn-group {
        border: 2px solid #ddd;
        border-radius: 6px;

        .btn.btn-primary {
            background: $background-color-2-light-alternate;
            background: $gradient-2-light;
            background: $-webkit-gradient-2-light;
            background: $-moz-gradient-2-light;
            background: $-o-gradient-2-light;
            border: none;
        }
    }

    #timeline {
        position: relative;
        padding-top: 150px;
        margin-top: 2em;
        margin-bottom: 2em;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            width: 4px;
            left: 50%;
            margin-left: -2px;
        }
    }

    .timeline-content {
        margin-left: 0;
    }

    .timeline-hide {
        display:none;
    }

    .timeline-block {
        position: relative;

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child,
        &:last-child {
            margin-bottom: 0;
            top: 10px;
        }

        .read-more p {
            margin-bottom: 0; 
            font-size: 12px !important; 
        }

        &:nth-child(odd) .timeline-img-container {
            top: 15px;
        }
    }

    .timeline-block-alt {
        height: 25px;
    }

    .timeline-block:last-child .timeline-img-container,
    .timeline-block-alt:last-child .timeline-img-container-alt {
        top: 10px;
    }

    .timeline-img-container {
        border-radius: 50%;
        position: absolute;
        top: 15px;
        width: 20px;
        height: 20px;
        left: 50%;
        margin-left: -10px;
        /* Force Hardware Acceleration in WebKit */
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden;

        &.top {
            background-color: transparent;
            border: 0;
            border-radius: 50%;
            position: absolute;
            top: -50px;
            width: 150px;
            height: 150px;
            left: 50%;
            margin-left: -75px;
            margin-top: -180px;
            /* Force Hardware Acceleration in WebKit */
            -webkit-transform: translateZ(0);
            -webkit-backface-visibility: hidden;
            margin-bottom: 30px;
    
            img {
                width: 150px;
                height: 150px;
                border-radius: 50%;
                border: 4px solid #ccc;
                margin-bottom: -30px;
            }
        }

        &.is-hidden {
            visibility: hidden;
        }
    }

    .timeline-img-container-alt {
        border-radius: 50%;
        position: relative;
        width: 20px;
        height: 20px;
        left: 50%;
        margin-left: -10px;
        /* Force Hardware Acceleration in WebKit */
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        color: #fff;

        &.is-hidden {
            visibility: hidden;
        }

    }

    .timeline-img-container.bounce-in,
    .timeline-img-container-alt.bounce-in {
        visibility: visible;
        -webkit-animation: bounce-1 0.6s;
        -moz-animation: bounce-1 0.6s;
        animation: bounce-1 0.6s;
    }

    .timeline-content {
        position: relative;
        border-radius: 10px;
        display: block;
        cursor: pointer;

        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        h2 {
            font-size: 22px !important;
            color: #333;
            margin-top: 0;
            padding-bottom: 10px;
        }

        .read-more, 
        .date {
            margin-right: 10px;
        }

        p {
            font-size: 16px !important;
            line-height: 24px;
        }

        &.is-hidden {
            visibility: hidden;
        }
    }


    .timeline-block-content {
        margin-bottom: 15px;

        .heading {
            padding: 10px 20px;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;

            h2 {
                color: #fff;
                margin:0;
                padding: 0;
            }
        }

        .heading-secondary {
            padding:10px 20px;

            p {
                margin-bottom: 5px;
                
                i {
                    width: 25px;
                }
            }

            .duration {
                font-style: italic;
            }
        }

        .text {
            padding: 15px 20px;
        }

    }
    .modal-content .modal-body {
        .duration {
            font-style: italic;
        }

        .modal-content .modal-body .separator {
            padding: 0 10px;
        }
    }
}

@media (max-width:768px) {
    #timeline-main {
        .read-more .read-more-click {
            display: none;
        }

        .btn-group {
            margin-top: 10px;
        }

        #timeline {
            padding-top: 0;

            &::before {
                left: -0px;
            }
        }

        .timeline-content {
            margin-top: 60px;
            padding-bottom: 40px;
            margin-left: 10px;
        }

        .timeline-block-content {
            margin: 10px 0 10px 10px;
            padding-bottom: 0px;
        }

        .timeline-img-container {
            left: -15px;

            &.top {
                display: inline-block;
                border-radius: 50%;
                position: relative;
                width: 100px;
                height: 100px;
                left: 0;
                margin-left: -50px;
                margin-bottom: 30px;
        
                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    border: 3px solid #ccc;
                    left: 0;
                    margin-bottom: 30px;
                }
            }
        }

        .timeline-img-container-alt {
            left: -15px;
        }

        .timeline-block {
            .timeline-content {
                &::before {
                    position: absolute;
                    top: 12px;
                    left: -10px;
                    display: inline-block;
                    border-top: 10px solid transparent;
                    border-left: 0 solid #ccc;
                    border-bottom: 10px solid transparent;
                    content: " ";
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }

                &::after {
                    position: absolute;
                    top: 13px;
                    left: -8px;
                    display: inline-block;
                    border-top: 9px solid transparent;
                    border-left: 0 solid #fff;
                    border-bottom: 9px solid transparent;
                    content: " ";
                }

                &.bounce-in {
                    -webkit-animation: bounce-2-inverse 0.6s;
                    -moz-animation: bounce-2-inverse 0.6s;
                    animation: bounce-2-inverse 0.6s;
                }
            }
        }
    }
}

@media only screen and (min-width:769px) and (max-width: 1169px) {
    #timeline-main {
        .read-more .read-more-click {
            display: none;
        }

        #timeline {
            padding-top: 0;

            &::before {
                left: -20px;
            }
        }

        .timeline-img-container {
            left: -35px;

            &.top {
                display: inline-block;
                border-radius: 50%;
                position: relative;
                width: 100px;
                height: 100px;
                left: 0;
                margin-left: -85px;
                margin-bottom: 30px;

                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    border: 3px solid #ccc;
                    left: 0;
                    margin-bottom: 30px;
                }
            }
        }

        .timeline-img-container-alt {
            left: -35px;
        }

        .timeline-block {
            .timeline-content {
                &::before {
                    position: absolute;
                    top: 10px;
                    left: -15px;
                    display: inline-block;
                    border-top: 15px solid transparent;
                    border-left: 0 solid #ccc;
                    border-bottom: 15px solid transparent;
                    content: " ";
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }

                &::after {
                    position: absolute;
                    top: 11px;
                    left: -13px;
                    display: inline-block;
                    border-top: 14px solid transparent;
                    border-left: 0 solid #fff;
                    border-bottom: 14px solid transparent;
                    content: " ";
                }

                &.bounce-in {
                    -webkit-animation: bounce-2-inverse 0.6s;
                    -moz-animation: bounce-2-inverse 0.6s;
                    animation: bounce-2-inverse 0.6s;
                }
            }
        }
    }
}

@media only screen and (min-width: 1170px) {  
    #timeline-main {
        .read-more .read-more-tap {
            display: none;
        }

        .timeline-content {
            width: 47%;
        }

        #timeline {
            margin-top: 5em;
            margin-bottom: 3em;
        }

        .timeline-block {
            .timeline-img-container.top {
                top: -10px;
            }
        
            &:nth-child(even) .timeline-content {
                float: right;

                .read-more {
                    float: right;
                }

                .date {
                    left: auto;
                    right: 122%;
                    text-align: right;
                }

                &::before {
                    position: absolute;
                    top: 10px;
                    left: -15px;
                    display: inline-block;
                    border-top: 15px solid transparent;
                    border-left: 0 solid #ccc;
                    border-bottom: 15px solid transparent;
                    content: " ";
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }

                &::after {
                    position: absolute;
                    top: 11px;
                    left: -13px;
                    display: inline-block;
                    border-top: 14px solid transparent;
                    border-left: 0 solid #fff;
                    border-bottom: 14px solid transparent;
                    content: " ";
                }
                
                &.bounce-in {
                    -webkit-animation: bounce-2-inverse 0.6s;
                    -moz-animation: bounce-2-inverse 0.6s;
                    animation: bounce-2-inverse 0.6s;
                }
            }

            &:nth-child(odd) .timeline-content {
                &::before {
                    position: absolute;
                    top: 10px;
                    right: -15px;
                    display: inline-block;
                    border-top: 15px solid transparent;
                    border-right: 0 solid #ccc;
                    border-bottom: 15px solid transparent;
                    content: " ";
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }

                &::after {
                    position: absolute;
                    top: 11px;
                    right: -13px;
                    display: inline-block;
                    border-top: 14px solid transparent;
                    border-right: 0 solid #fff;
                    border-bottom: 14px solid transparent;
                    content: " ";
                }

                &.bounce-in {
                    visibility: visible;
                    -webkit-animation: bounce-2 0.6s;
                    -moz-animation: bounce-2 0.6s;
                    animation: bounce-2 0.6s;
                }
            }
        }
    }
}

@-webkit-keyframes bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

@-moz-keyframes bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-moz-keyframes bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-moz-keyframes bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}